import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    $(this.element).select2({
      placeholder: this.data.get('placeholder'),
      theme: 'bootstrap4',
      width: '100%'
    })

    this.destroy = this.destroy.bind(this)
    document.addEventListener('turbolinks:before-cache', this.destroy)
  }

  disconnect() {
    document.removeEventListener('turbolinks:before-cache', this.destroy)
  }

  // Destroy select2 to prevent another initialization
  destroy() {
    $(this.element).select2('destroy')
  }
}
