import { Controller } from 'stimulus'

export default class extends Controller {

  initialize() {
      var stripe = Stripe('pk_live_aqcZrHHoSGj7CsHmX6tihojU00hkV0BZGF');
      var stripePayButton = document.querySelector('.subscribe-btn')

      stripePayButton.addEventListener('click', e => {
          e.preventDefault()

          const CHECKOUT_SESSION_ID = stripePayButton.parentElement.dataset.stripe

          stripe.redirectToCheckout({
              sessionId: CHECKOUT_SESSION_ID
          }).then((result) => {
              // handle any result data you might need
              console.log(result.error.message)
          })
      })
  }
}
