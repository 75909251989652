import { Controller } from 'stimulus'
import { DirectUpload } from '@rails/activestorage'
import { post } from 'jquery'

// https://medium.com/typecode/a-strategy-for-handling-multiple-file-uploads-using-javascript-eb00a77e15f
export default class extends Controller {
  static targets = ['inputSection', 'input', 'label', 'validFileTypes', 'files', 'template']

  attachmentContainerSelector = '.multiple_file_upload__attachment'
  // removedAttachmentContainerClass = 'multiple_file_upload__attachment--remove'

  connect() {
    this.showAvailableFileTypes()
  }

  update() {
    const files = this.inputTarget.files
    if (files) this.addUploadables(files)
  }

  addUploadables(files) {
    Array.from(files).forEach(file => this.uploadFile(file))
    // you might clear the selected files from the input
    this.inputTarget.value = null
  }

  uploadFile(file) {
    if (!this.validateFileType(file.type)) {
      this.inputSectionTarget.classList.add('multiple_file_upload__custom-file--error')
      return
    }

    this.inputSectionTarget.classList.remove('multiple_file_upload__custom-file--error')
    const uniqid = this.uniqid(16)
    this.addFileToList(uniqid, file.name)
    const url = this.inputTarget.dataset.directUploadUrl
    const upload = new DirectUpload(file, url)
    upload.create((error, blob) => {
      if (error) {
        // TODO: Handle the error
      } else {
        post({
          url: this.data.get('postUrl'),
          data: { blob: blob.signed_id },
          success: data => {
            if (data.success) {
              var container = this.filesTarget.querySelector('#' + uniqid)
              container.classList.remove('uploading')
              container.setAttribute('data-attachment-id', data.attachment_id)
            }
          }
        })
      }
    })
  }

  uniqid(len, charSet) {
    // A custom CSS selector must be composed by -?[_a-zA-Z]+[_-a-zA-Z0-9]* chars
    // Reference from
    // https://pineco.de/css-quick-tip-the-valid-characters-in-a-custom-css-selector/
    charSet = charSet || 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
    let randomString = ''
    for (let i = 0; i < len; i++) {
      const randomPoz = Math.floor(Math.random() * charSet.length)
      randomString += charSet.substring(randomPoz, randomPoz + 1)
    }
    return randomString
  }

  addFileToList(id, filename) {
    const newrow = this.templateTarget.cloneNode(true)
    newrow.removeAttribute('data-target')
    // newrow.setAttribute('data-attachment-id', id)
    newrow.setAttribute('id', id)
    const span = newrow.querySelector('.multiple_file_upload__filename')
    span.innerText = filename
    newrow.classList.remove('d-none')
    newrow.classList.add('d-flex')
    this.filesTarget.appendChild(newrow)
  }

  remove(event) {
    if (confirm(this.filesTarget.dataset.msgConfirmRemove)) {
      const container = event.srcElement.closest(this.attachmentContainerSelector)
      const attachmentId = container.getAttribute('data-attachment-id')
      post({
        url: this.data.get('removeUrl'),
        data: { attachment_id: attachmentId },
        success: data => {
          if (data.success) {
            container.parentNode.removeChild(container)
          }
        }
      })
    }
  }

  validateFileType(type) {
    return this.getAvailableFileTypes().length === 0 || this.getAvailableFileTypes().lastIndexOf(type) >= 0
  }

  showAvailableFileTypes() {
    if (this.getAvailableFileTypes().length > 0) {
      const list = [...this.getAvailableFileTypes().matchAll(/[^\/]+\/([^ ]+)/g)].map(x => '.' + x[1]).join(', ')
      this.inputSectionTarget.querySelector('.multiple_file_upload__file-types-list').innerText = list
    } else {
      const container = this.validFileTypesTarget
      container.parentNode.removeChild(container)
    }
  }

  getAvailableFileTypes() {
    return this.data.get('contentTypes')
  }
}
