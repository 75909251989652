import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    $(this.element).select2({
      placeholder: this.data.get('placeholder'),
      theme: 'bootstrap4',
      width: '100%'
    })

    $(this.element).on('select2:select', e => {
      $.post('/round/tournament_teams', {
        round_id: this.data.get('roundId'),
        sport_club_id: e.params.data.id
      })
    })

    this.destroy = this.destroy.bind(this)
    document.addEventListener('turbolinks:before-cache', this.destroy)
  }

  disconnect() {
    document.removeEventListener('turbolinks:before-cache', this.destroy)
  }

  destroy() {
    $(this.element).select2('destroy')
  }
}
