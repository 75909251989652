import { Controller } from 'stimulus'
import { connect } from 'mqtt'

export default class extends Controller {
  connect() {
    const topic = `tournament_team_category/${this.data.get('id')}`
    const mqttBrokerUrl = this.data.get('url')

    this.client = connect(
      '/',
      { protocol: 'wss', host: mqttBrokerUrl, port: 9001 }
    )

    this.client.on('message', (_topic, payload) => {
      const roundMatchUpdated = JSON.parse(payload.toString()).round_match
      const roundMatch = $(`#round-match-${roundMatchUpdated.id}`)
      if (roundMatch.length > 0) {
        roundMatch
          .find(`#round-match-team-${roundMatchUpdated.first_round_match_team.id} .goals`)
          .text(roundMatchUpdated.first_round_match_team.goals)

        roundMatch
          .find(`#round-match-team-${roundMatchUpdated.second_round_match_team.id} .goals`)
          .text(roundMatchUpdated.second_round_match_team.goals)

        if (roundMatchUpdated.started_at && !roundMatchUpdated.ended_at) {
          roundMatch.addClass('live-round-match--ongoing')
        } else {
          roundMatch.removeClass('live-round-match--ongoing')
        }
      }

      // this.client.on('connect', () => console.log('client connect'))
      // this.client.on('close', () => console.log('client close'))
    })

    this.client.subscribe(topic)

    this.destroy = this.destroy.bind(this)
    document.addEventListener('turbolinks:before-cache', this.destroy)
  }

  disconnect() {
    document.removeEventListener('turbolinks:before-cache', this.destroy)
  }

  // Close connection to prevent another initialization
  destroy() {
    this.client.end()
  }
}
