import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['input', 'label']

  update() {
    const file = this.inputTarget.files[0]
    if (file) this.setName(file.name)
  }

  setName(name) {
    this.labelTarget.innerText = name
  }
}
