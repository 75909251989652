import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    const modal = $(this.element)
  }

  disconnect() {
    document.removeEventListener('turbolinks:before-cache', this.hide)
  }

  handleChange() {
    const next_button = $('button[data-orientation="next"]')
    const competition_type = $(this.element).val()

    if (competition_type == '') {
      next_button.attr('disabled', 'disabled')
    } else {
      next_button.removeAttr('disabled')
    }
  }

  hide() {

  }
}
