import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    const sourceElement = $(this.element)
  }

  disconnect() {
    document.removeEventListener('turbolinks:before-cache', this.hide)
  }

  toggle() {
    const sourceElement = $(this.element)
    const destination = sourceElement.attr('data-toggle-id')

    if (sourceElement.is(':checked')) {
      $(destination).show()
    } else {
      $(destination).hide()
    }
  }

  hide() {

  }
}
