import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    const sourceElement = $(this.element)
  }

  disconnect() {
    document.removeEventListener('turbolinks:before-cache', this.hide)
  }

  switch() {
    const sourceElement = $(this.element)
    const destinations = sourceElement.attr('data-switch-id').split(',')

    destinations.forEach(element => {
      $(element).toggle()
    });
  }

  hide() {

  }
}
