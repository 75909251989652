import { Controller } from 'stimulus'
import { Modal } from 'bootstrap'

export default class extends Controller {
  connect() {
    const myModalEl = $(this.element)
    const modal = Modal.getOrCreateInstance(myModalEl)

    myModalEl[0].addEventListener('show.bs.modal', function (event) {
      const link = event.relatedTarget.getAttribute('href');

      fetch(link).then(res => res.text()).then(html => {
        myModalEl[0].querySelector('.modal-body').innerHTML = html
      });
    })

    this.hide = this.hide.bind(this)
    document.addEventListener('turbolinks:before-cache', this.hide)
  }

  disconnect() {
    document.removeEventListener('turbolinks:before-cache', this.hide)
  }

  hide() {
    $(this.element).hide()
  }
}
