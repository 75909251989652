import { Controller } from 'stimulus'
// import { Modal } from 'bootstrap'
import { get } from 'jquery'

export default class extends Controller {
  connect() {
    const myModalEl = $(this.element)
    // const modal = Modal.getOrCreateInstance(myModalEl)

    myModalEl[0].addEventListener('show.bs.modal', function (event) {
      const button = $(event.relatedTarget)
      if (button.attr('href')) myModalEl.find('.modal-body').load(button.attr('href'))
      else console.error('button should have `href` attribute')
    })

    this.hide = this.hide.bind(this)
    document.addEventListener('turbolinks:before-cache', this.hide)
  }

  loadStep() {
    const modalEl = $(this.element)

    const selectedType = modalEl.find('#tournament_type').val()

    if (selectedType == 'championship') {
      modalEl.find('#tournament_championship').val('true')

      modalEl.find('.championship-field').show()
      modalEl.find('.tournament-field').hide()
    } else {
      modalEl.find('#tournament_championship').val('false')

      modalEl.find('.championship-field').hide()
      modalEl.find('.tournament-field').show()
    }

    if (selectedType != "") {
      modalEl.find('#form-fields').show()
    } else {
      modalEl.find('#form-fields').hide()
    }
  }

  // connect() {
  //   const myModalEl = $(this.element)
  //   const modal = Modal.getOrCreateInstance(myModalEl)

  //   myModalEl[0].addEventListener('show.bs.modal', function (event) {
  //     const button = $(event.relatedTarget)
  //     if (button.attr('href')) myModalEl.find('.modal-body').load(button.attr('href'))
  //     else console.error('button should have `href` attribute')
  //   })

  //   this.hide = this.hide.bind(this)
  //   document.addEventListener('turbolinks:before-cache', this.hide)

  //   this.loadModalSteps()
  // }

  // // Not working with Bootstrap5
  // loadModalSteps() {
  //   const modal = $(this.element)
  //   modal.modalSteps({
  //     btnCancelHtml: "Cancel",
  //     btnPreviousHtml: "Previous",
  //     btnNextHtml: "Next",
  //     btnLastStepHtml: "Complete",
  //     disableNextButton: true,
  //     completeCallback: function () {
  //       console.log('completed')

  //       const form = $('#form-new-tournament')
  //       form.trigger('submit')
  //     },
  //     callbacks: {
  //       '1': function () {
  //         // enable next button
  //         const next_button = $('button[data-orientation="next"]')
  //         next_button.show()

  //         // 
  //         const final_button = $('button[data-orientation="final"]')
  //         final_button.hide();
  //       },
  //       '2': function () {
  //         // append steps
  //         get({
  //           url: '/tournaments/form_steps',
  //           data: { type: $('#tournament_type').val() },
  //           success: data => {
  //             $('#container-new-tournament').html(data)
  //           }
  //         })

  //         // enable next button
  //         const next_button = $('button[data-orientation="next"]')
  //         next_button.hide()

  //         const final_button = $('button[data-orientation="final"]')
  //         final_button.show();
  //       }
  //     },
  //   });
  // }

  disconnect() {
    document.removeEventListener('turbolinks:before-cache', this.hide)
  }

  hide() {
    $(this.element).hide()
  }
}
