import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['firstGoals', 'secondGoals']

  connect() {
    this.firstId = this.data.get('first-team-id')
    this.firstGoals = Number(this.data.get('first-team-goals'))
    this.secondId = this.data.get('second-team-id')
    this.secondGoals = Number(this.data.get('second-team-goals'))

    this.renderFirstGoals()
    this.renderSecondGoals()

    console.log(this.firstId, this.firstGoals, this.secondId, this.secondGoals)
  }

  addFirstGoal() {
    this.firstGoals += 1
    this.renderFirstGoals()
  }

  removeFirstGoal() {
    this.firstGoals -= 1
    this.renderFirstGoals()
  }

  addSecondGoal() {
    this.secondGoals += 1
    this.renderSecondGoals()
  }

  removeSecondGoal() {
    this.secondGoals -= 1
    this.renderSecondGoals()
  }

  renderFirstGoals() {
    if (this.hasFirstGoalsTarget) this.firstGoalsTarget.innerHTML = this.firstGoals
  }

  renderSecondGoals() {
    if (this.hasSecondGoalsTarget) this.secondGoalsTarget.innerHTML = this.secondGoals
  }
}
