import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['overlay', 'navigation']

  dismiss() {
    this.overlayTarget.classList.remove('layout__overlay--active')
    this.navigationTarget.classList.remove('layout__navigation--active')
  }

  toggle() {
    this.overlayTarget.classList.toggle('layout__overlay--active')
    this.navigationTarget.classList.toggle('layout__navigation--active')
  }
}
