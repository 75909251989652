import { Controller } from 'stimulus'
import { post } from 'jquery'

export default class extends Controller {
  static targets = ['goals', 'removeBtn', 'addBtn']

  connect() {
    this.id = this.data.get('id')
    this.goals = Number(this.data.get('goals'))
    this.reporter = this.data.get('reporter-sport-club-id')
    this.role = this.data.get('role')

    if (this.role == null) {
      this.role = 'playing_field_manager'
    }

    this.render(false)
  }

  // azioni: add, remove
  add() {
    this.goals += 1
    this.update()
  }

  remove() {
    this.goals -= 1
    this.update()
  }

  // helper privati: update, render
  update() {
    this.render(true)
    post({
      url: `/${this.role}/round_match_teams/${this.id}.json`,
      data: JSON.stringify({ goals: this.goals, reporter: this.reporter }),
      contentType: 'application/json',
      dataType: 'json',
      success: ({ goals }) => {
        this.goals = goals
        this.render(false)
      }
    })
  }

  render(isLoading) {
    if (this.hasGoalsTarget) this.goalsTarget.innerHTML = this.goals

    if (this.hasRemoveBtnTarget) {
      if (isLoading || this.goals <= 0) {
        this.removeBtnTarget.classList.add('disabled')
        this.removeBtnTarget.setAttribute('disabled', 'disabled')
      } else {
        this.removeBtnTarget.classList.remove('disabled')
        this.removeBtnTarget.removeAttribute('disabled')
      }
    }

    if (this.hasAddBtnTarget) {
      if (isLoading) {
        this.addBtnTarget.classList.add('disabled')
        this.addBtnTarget.setAttribute('disabled', 'disabled')
      } else {
        this.addBtnTarget.classList.remove('disabled')
        this.addBtnTarget.removeAttribute('disabled')
      }
    }
  }
}
