import { Controller } from 'stimulus'
import SignaturePad from 'signature_pad'

export default class extends Controller {
  static targets = ['canvas', 'field']

  connect() {
    // this.element.style.height = window.document.documentElement.clientHeight + 'px'
    // this.element.style.maxHeight = window.document.documentElement.clientHeight + 'px'

    setTimeout(() => {
      this.prepareCanvas(this.canvasTargets[0], this.firstSignaturePad, this.fieldTargets[0])
      this.prepareCanvas(this.canvasTargets[1], this.secondSignaturePad, this.fieldTargets[1])
    }, 100)

    this.destroy = this.destroy.bind(this)
    document.addEventListener('turbolinks:before-cache', this.destroy)
  }

  disconnect() {
    document.removeEventListener('turbolinks:before-cache', this.destroy)
  }

  destroy() {
    if (this.firstSignaturePad) this.firstSignaturePad.clear()
    if (this.secondSignaturePad) this.secondSignaturePad.clear()
  }

  prepareCanvas(canvas, signaturePad, field) {
    const { width, height } = canvas.parentElement.getBoundingClientRect()
    canvas.setAttribute('width', parseInt(width))
    canvas.setAttribute('height', parseInt(height))

    signaturePad = new SignaturePad(canvas, {
      backgroundColor: 'rgb(255, 255, 255)',
      onEnd: () => {
        field.value = signaturePad.toDataURL()
      }
    })

    signaturePad.fromDataURL(field.value)
  }
}
