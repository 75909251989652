import { Controller } from 'stimulus'

export default class extends Controller {

  initialize() {
    $('#stepper .collapsable-header').click(function () {
        $('#step-open').toggleClass('d-none');
    })
  }
}
