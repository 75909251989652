/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import Rails from 'rails-ujs'
import Turbolinks from 'turbolinks'

// import 'bootstrap/dist/js/bootstrap.bundle'
import "bootstrap"
import "@popperjs/core"
import 'select2'

//import '../javascripts/common/modal-steps.min'
import '../javascripts/common/turbolinks_hacks'
import '../javascripts/application/stimulus/application'
import '../javascripts/application/ready'

require('@rails/activestorage').start()
Rails.start()
Turbolinks.start()
