import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.code = this.data.get('code').toString()

    this.updatePageLocation = this.updatePageLocation.bind(this)
    document.addEventListener('turbolinks:load', event => this.updatePageLocation(event))
  }

  updatePageLocation(event) {
    if (typeof gtag === 'function') {
      // gtag is imported from Google Analytics script
      gtag('config', this.code, {
        page_location: event.data.url
      })
    }
  }
}
