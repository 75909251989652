import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    $(this.element).select2({
      theme: 'bootstrap4',
      width: '100%'
    })

    $(this.element).on('select2:select', e => {
      var tournament = e.params.data.id
      window.location.href = '/live/tournaments/'+tournament;
    })

    this.destroy = this.destroy.bind(this)
    document.addEventListener('turbolinks:before-cache', this.destroy)
  }

  disconnect() {
    document.removeEventListener('turbolinks:before-cache', this.destroy)
  }

  destroy() {
    $(this.element).select2('destroy')
  }
}
