import { Controller } from 'stimulus'
import { Calendar } from '@fullcalendar/core'
import bootstrapPlugin from '@fullcalendar/bootstrap'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import locale from '@fullcalendar/core/locales/it'

export default class extends Controller {
  static targets = ['root', 'input']

  initialize() {
    const parsedEvents = JSON.parse(this.data.get('events'))
    const days = JSON.parse(this.data.get('days'))
    const eventDrop = ({ event, _revert }) => {
      this.syncInputValue(event.id, event.start.toISOString())
    }

    parsedEvents.forEach(event => this.syncInputValue(event.id, event.start))

    this.calendar = new Calendar(this.rootTarget, {
      plugins: [bootstrapPlugin, timeGridPlugin, interactionPlugin],
      themeSystem: 'bootstrap',
      defaultView: 'timeGrid',
      eventStartEditable: this.data.get('readonly') === 'false',
      locale,

      duration: { days },
      header: true,
      defaultDate: parsedEvents.length > 0 ? parsedEvents[0].start.split('T')[0] : null,
      events: parsedEvents,

      columnHeader: true,
      allDaySlot: false,
      slotDuration: '0:15:00',
      slotLabelFormat: {
        hour: 'numeric',
        minute: '2-digit',
        omitZeroMinute: false,
        meridiem: 'short'
      },
      minTime: '09:00:00',
      maxTime: '20:00:00',
      height: 14 * 4 * 10 + 1, // 14 ore * 4 slot ogni ora * 10px per slot + 1px per il bordo

      eventDrop
    })
  }

  connect() {
    this.render()
    setTimeout(this.render.bind(this), 50)

    this.destroy = this.destroy.bind(this)

    document.addEventListener('turbolinks:before-cache', this.destroy)
  }

  render() {
    this.calendar.render()
  }

  syncInputValue(field, value) {
    const input = this.inputTargets.find(target => target.getAttribute('name').includes(`[${field}`))
    if (input) {
      input.value = value
    }
  }

  disconnect() {
    document.removeEventListener('turbolinks:before-cache', this.destroy)
  }

  // Destroy fullcalendar to prevent another initilization
  destroy() {
    this.calendar.destroy()
  }
}
