import { Controller } from 'stimulus'

export default class extends Controller {

  initialize() {
    $('.favorite').click(function () {
        $(this).toggleClass('live__favorite-active')
    })
  }
}
