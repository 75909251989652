import { Controller } from 'stimulus'
import Cookies from 'js-cookie'
import { ajax } from 'jquery'

export default class extends Controller {
  static targets = ['play', 'pause', 'display', 'removeBtn', 'addBtn']

  // se il timer sta andando, la fonte di verita' e' startDate
  // se il timer e' in pausa, la fonte di verita' e' elapsedTimeMs
  connect() {
    this.id = this.data.get('id')
    this.cookieName = `timer-round-match-${this.id}`
    this.timerInterval = null

    // Start match right away if managed by teams
    var playMatch = this.data.element.attributes.getNamedItem('data-play')
    if (playMatch != null && playMatch.value == "true") {
      this.startDate = null
      this.play()
      this.renderDisplay()
      return true
    }

    const json = Cookies.getJSON(this.cookieName)
    if (json && json.startDate) {
      this.elapsedTimeMs = new Date().getTime() - json.startDate
      this.play()
    } else if (json && json.elapsedTimeMs) {
      this.elapsedTimeMs = json.elapsedTimeMs
      this.startDate = null
    } else {
      this.elapsedTimeMs = 0
      this.startDate = null
      this.hideButtonForEditGoals()
    }
    this.renderDisplay()
  }

  disconnect() {
    if (this.timerInterval) {
      clearInterval(this.timerInterval)
    }
  }

  // azioni: play, pause, stop
  play() {
    if (!this.timerInterval) {
      this.startDate = new Date().getTime() - this.elapsedTimeMs
      this.togglePlayPause()
      this.showButtonForEditGoals()
      Cookies.set(this.cookieName, { startDate: this.startDate })

      this.timerInterval = setInterval(() => {
        this.elapsedTimeMs = new Date().getTime() - this.startDate
        this.renderDisplay()
      }, 200)

      // TODO: Can be improved to avoid repeated calls?
      this.startMatch()
    }
  }

  pause() {
    if (this.timerInterval) {
      this.elapsedTimeMs = new Date().getTime() - this.startDate
      this.togglePlayPause()
      Cookies.set(this.cookieName, { elapsedTimeMs: this.elapsedTimeMs })

      clearInterval(this.timerInterval)
      this.timerInterval = null
    }
  }

  stop() {
    if (this.timerInterval) {
      this.togglePlayPause()

      clearInterval(this.timerInterval)
      this.timerInterval = null
    }
    Cookies.remove(this.cookieName)

    this.elapsedTimeMs = 0
    this.startDate = null
    this.renderDisplay()
  }

  // helper privati: togglePlayPause, renderDisplay, startMatch
  togglePlayPause() {
    if (this.hasPlayTarget) this.playTarget.classList.toggle('d-none')
    if (this.hasPauseTarget) this.pauseTarget.classList.toggle('d-none')
  }

  showButtonForEditGoals() {
    if (this.hasRemoveBtnTarget) this.removeBtnTargets.forEach(removeBtnTarget => removeBtnTarget.classList.remove('d-none'))
    if (this.hasAddBtnTarget) this.addBtnTargets.forEach(addBtnTarget => addBtnTarget.classList.remove('d-none'))
  }

  hideButtonForEditGoals() {
    if (this.hasRemoveBtnTarget) this.removeBtnTargets.forEach(removeBtnTarget => removeBtnTarget.classList.add('d-none'))
    if (this.hasAddBtnTarget) this.addBtnTargets.forEach(addBtnTarget => addBtnTarget.classList.add('d-none'))
  }

  renderDisplay() {
    const minutes = Math.floor(this.elapsedTimeMs / (1000 * 60))
    const seconds = Math.floor((this.elapsedTimeMs % (1000 * 60)) / 1000)

    if (this.hasDisplayTarget) {
      this.displayTargets.forEach(target => {
        target.innerHTML = `${minutes}:${String(seconds).padStart(2, '0')}`
      })
    }
  }

  startMatch() {
    ajax({
      url: `/playing_field_manager/round_matches/${this.id}/match_start.json`,
      data: JSON.stringify({ started_at: new Date().toISOString() }),
      type: 'PUT',
      contentType: 'application/json',
      dataType: 'json'
      // success: () => { }
    })
  }
}
