import toastr from 'toastr'

toastr.options = {
  positionClass: 'toast-top-left',
  preventDuplicates: true,
  newestOnTop: true,
  closeButton: false,
  timeOut: 7000,
  fadeIn: 700,
  // fadeAway:       700,
  // fadeOut:        700,
  progressBar: true,
  showMethod: 'slideDown'
  // hideMethod: 'slideUp',
  // closeDuration: 300,
}

const toastrOverwritten = { ...toastr } // lo copio

// sovraiscrivo il metodo error
toastrOverwritten.error = (title, message, options) => {
  toastr.error(title, message, {
    ...options,
    progressBar: false,
    hideDuration: 500,
    timeOut: 60 * 60 * 1000,
    extendedTimeOut: 60 * 60 * 1000,
    closeButton: true
  })
}

global.toastr = toastrOverwritten
export default toastrOverwritten
