import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    console.log('Connected to Stimulus!', this.element)
  }

  hello() {
    console.log('Hello Stimulus!')
  }
}
