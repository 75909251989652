import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['image', 'input', 'label']

  update() {
    const file = this.inputTarget.files[0]
    if (file) this.setName(file.name)
  }

  remove() {
    if (this.data.get('is-logo-attached') === 'true' && confirm(this.inputTarget.dataset.msgConfirmRemove)) {
      this.inputTarget.value = ''
      this.insertHiddenField()
      this.setName('Scegli il file')
      this.setImage(this.data.get('image-fallback'))
    } else if (this.inputTarget.value !== '' && confirm(this.inputTarget.dataset.msgConfirmRemove)) {
      this.inputTarget.value = ''
      this.setName('Scegli il file')
    }
  }

  // private

  insertHiddenField() {
    const hiddenField = document.createElement('input')
    hiddenField.type = 'hidden'
    hiddenField.name = this.inputTarget.name
    hiddenField.value = ''

    this.inputTarget.parentNode.insertBefore(hiddenField, this.inputTarget)
  }

  setName(name) {
    this.labelTarget.innerText = name
  }

  setImage(src) {
    this.imageTarget.src = src
  }
}
