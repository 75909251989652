import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['exclude']

  visit(event) {
    if (!this.element.hasAttribute('href')) {
      throw new Error("Aggiungi l'attributi href all'elemento in cui stai usando il controller di stimulus link")
    }
    if (this.hasExcludeTarget) {
      var shouldExclude = this.excludeTargets
        .map(target => event.path.includes(target))
        .reduce((acc, result) => acc || result, false)

      if (shouldExclude) {
        return
      }
    }

    document.location = this.element.getAttribute('href')
  }
}
