import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['matching', 'roundsNumberWithDirects', 'roundsNumberNoDirects', 'roundsNumberWithRoundRobinFinals']

  connect() {
    if (this.hasMatchingTarget) {
      this.matchingTarget.addEventListener('change', event => {
        this.render(event.target.value)
      })

      this.render(this.matchingTarget.value)
    }

    this.render = this.render.bind(this)
    document.addEventListener('turbolinks:before-cache', this.render)
  }

  disconnect() {
    // if (this.hasMatchingTarget) {
    //   this.matchingTarget.removeEventListener('change')
    // }

    document.removeEventListener('turbolinks:before-cache', this.render)
  }

  render(matching) {
    if (matching === 'round_robin_finals') {
      // Show roundsNumberWithRoundRobinFinals
      if (this.hasRoundsNumberWithDirectsTarget) this.disableElement(this.roundsNumberWithDirectsTarget)
      if (this.hasRoundsNumberNoDirectsTarget) this.disableElement(this.roundsNumberNoDirectsTarget)
      if (this.hasRoundsNumberWithRoundRobinFinalsTarget) this.enableElement(this.roundsNumberWithRoundRobinFinalsTarget)
    } else if (matching === 'no_directs') {
      // Show roundsNumberNoDirects
      if (this.hasRoundsNumberWithDirectsTarget) this.disableElement(this.roundsNumberWithDirectsTarget)
      if (this.hasRoundsNumberNoDirectsTarget) this.enableElement(this.roundsNumberNoDirectsTarget)
      if (this.hasRoundsNumberWithRoundRobinFinalsTarget) this.disableElement(this.roundsNumberWithRoundRobinFinalsTarget)
    } else {
      // Show roundsNumberWithDirects
      if (this.hasRoundsNumberWithDirectsTarget) this.enableElement(this.roundsNumberWithDirectsTarget)
      if (this.hasRoundsNumberNoDirectsTarget) this.disableElement(this.roundsNumberNoDirectsTarget)
      if (this.hasRoundsNumberWithRoundRobinFinalsTarget) this.disableElement(this.roundsNumberWithRoundRobinFinalsTarget)
    }
  }

  disableElement(element) {
    element.classList.add('d-none')
    element.disabled = true
  }

  enableElement(element) {
    element.classList.remove('d-none')
    element.disabled = false
  }
}
